<template>
	<div style="background: #fff;padding: 15px;">
		<div>
			
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other.js'
	export default {
		data() {
			return {
				loading: false,

				searchKey: '',
				statisTime: '',
				currentPage: 1,
				pageSize: 20,
				Total: 0,

				tableList: [],

				imgUrl: config.IMG_BASE,
				defaultHeader:config.DEFAULT_HEADER,
        exportUrl: config.EXPORT_URL,
			};
		},
		beforeMount() {
			this.searchKey = this.$route.query.phone||'';
			this.initTime()
			this.getListData()
		},
		methods: {
			initTime(){
				let date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
				this.statisTime = year + "-" + month
			},
			async getListData() {
				this.loading = true
				try {
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
						Keywords: this.searchKey,
						StatisTime: this.statisTime
					}
					let result = await apiList.agentSellAwardDetail(data)

					this.tableList = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
      openWindowOrder(orderNo){
        let url = '/order/orderDetailpage?Id=' + orderNo
				this.$common.openNewWindow(url)
      },
			goodsSearch() {
				this.currentPage = 1
				this.getListData()
			},
			async exportlist(){
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/agent-sell-award/detail-export?' +
						'&Keywords=' + this.searchKey +
						'&StatisTime=' + ((this.statisTime == null) ? '' : this.statisTime)
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getListData();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getListData();
			},
		},
	}
</script>

<style lang="less" scoped>
.table-container{
  .table-name{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
